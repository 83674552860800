export default class {
  static getImagePath(image) {
    let imagePath;

    if (this.has(image, 'relativePath')) {
      imagePath = `/assets/${image.relativePath}`;
    }

    if (image
      && this.has(image, 'childImageSharp')
      && this.has(image.childImageSharp, 'resize')
      && this.has(image.childImageSharp.resize, 'src')
    ) {
      imagePath = image.childImageSharp.resize.src;
    }

    return imagePath;
  }

  static has(obj, prop) {
    return obj && Object.prototype.hasOwnProperty.call(obj, prop);
  }

  static hasNestedProp(obj, propsArray) {
    let i;

    if (!obj) {
      return false;
    }

    for (i = 0; i < propsArray.length; i++) {
      if (!obj || !Object.prototype.hasOwnProperty.call(obj, propsArray[i])) {
        return false;
      }

      // eslint-disable-next-line no-param-reassign
      obj = obj[propsArray[i]];
    }

    return true;
  }
}
