import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, withPrefix } from 'gatsby';
import PageTemplate from 'templates/PageTemplate';
import Link from 'components/Link';
import Arrow from 'components/Arrow';
import ServicesEmployed from 'components/ServicesEmployed';
import StripEdge from 'images/strip-edge.jpg';
import Utils from '../utils';

const SuccessTemplate = ({ data, pathContext }) => {
  const { html } = data.record;
  const { previous, next } = pathContext;
  const {
    background,
    client,
    metadescription,
    services,
    title,
  } = data.record.frontmatter;
  let bgPath = Utils.getImagePath(background);
  const fullPath = `https://www.outvox.com/successes${data.record.fields.slug}`;

  if (!bgPath) {
    bgPath = StripEdge;
  }

  return (
    <PageTemplate className="success" metaDescription={metadescription}>
      <Helmet
        title={title}
        script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "http://schema.org",
            "@type": "WebPage",
            "url": "${fullPath}",
            "name": "${title}",
            "publisher": {
              "@type": "Organization",
              "name": "OutVox",
              "telephone": "202 379 3085",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.outvox.com${withPrefix('/assets/outvox-logo_gray.png')}"
              }
            }
          }`,
        }]}
        meta={[
          { property: 'og:title', content: title },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: fullPath },
          { property: 'og:image', content: `https://www.outvox.com${StripEdge}` },
        ]}
      />

      <header style={{ backgroundImage: `url(${bgPath})` }}>
        <div className="container--narrow success--title">
          <h1>{client}</h1>
          <h2>{title}</h2>
        </div>
      </header>

      <nav>
        <Link className="prev w-100 w-50-ns pv3" to={`/successes${previous.fields.slug}`}>
          <Arrow left color="#CFBDA1" />
          {previous.frontmatter.title}
        </Link>

        <Link className="next w-100 w-50-ns pv3 tr" to={`/successes${next.fields.slug}`}>
          {next.frontmatter.title}
          <Arrow color="#CFBDA1" />
        </Link>
      </nav>

      <section
        className="container--narrow content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />

      <div className="container">
        <ServicesEmployed services={services} />
      </div>
    </PageTemplate>
  );
};

export const pageQuery = graphql`
  query SuccessBySlug($slug: String!) {
    record: markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: {regex: "/(../successes)/.*.md$/"}
      frontmatter: { example: { ne: true } }
    ){
      html
      fields {
        postPath
        slug
      }
      frontmatter {
        background {
          childImageSharp {
            resize(height: 700, quality: 90) {
              src
            }
          }
          relativePath
        }
        client
        metadescription
        services
        title
      }
    }
  }
`;

SuccessTemplate.propTypes = {
  data: PropTypes.shape({
    record: PropTypes.object.isRequired,
  }).isRequired,
  pathContext: PropTypes.shape({
    previous: PropTypes.object.isRequired,
    next: PropTypes.object.isRequired,
  }).isRequired,
};

export default SuccessTemplate;
