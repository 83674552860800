import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import servicesArray from '../services';

const ServicesEmployed = ({ services }) => {
  const getServices = () => services.map((service) => {
    const record = servicesArray.find((arrayItem) => service === arrayItem.id);

    if (record) {
      return (
        <Card
          key={record.id}
          title={record.title}
          summary={record.summary}
          url={record.url}
          linkText="Learn More"
        />
      );
    }

    return null;
  });

  return (
    <aside>
      <h3>Services Employed</h3>

      <div className="card--list">
        {getServices()}
      </div>
    </aside>
  );
};

ServicesEmployed.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
};

export default ServicesEmployed;
